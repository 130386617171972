<template>
    <div>    <img src="@/assets/solutionpdf/smoke_1.jpg" alt="" />
    <img src="@/assets/solutionpdf/smoke_2.jpg" alt="" />
    <img src="@/assets/solutionpdf/smoke_3.jpg" alt="" />
    <img src="@/assets/solutionpdf/smoke_4.jpg" alt="" />
    <img src="@/assets/solutionpdf/smoke_5.jpg" alt="" />
    <img src="@/assets/solutionpdf/smoke_6.jpg" alt="" />
    <img src="@/assets/solutionpdf/smoke_7.jpg" alt="" />
    <img src="@/assets/solutionpdf/smoke_8.jpg" alt="" /></div>
</template>

<script>
export default {
    name: "smoke-solution"
}
</script>

<style scoped>
img {
  width: 100%;
}
</style>